import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/app/user/dashboard/Header";
import Welcome from "../../components/app/user/dashboard/Welcome";
import LinkedStores from "../../components/app/user/dashboard/ClientList";
import Footer from "../../components/app/user/dashboard/Footer";

import serverUrl from "../../useful/config";

const Dashboard = () => {
    const [searchParams] = useSearchParams();
    const cpf = searchParams.get("cpf");
    const [userName, setUserName] = useState("Usuário");
    const [availableCredit, setAvailableCredit] = useState(0);
    const username = 'admin';
    const password = 'admin';
    const credentials = btoa(`${username}:${password}`);

    useEffect(() => {
        const fetchData = async () => {
            if (cpf) {
                try {
                    const response = await fetch(`${serverUrl}extrato/usuario/${cpf}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${credentials}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        if (Array.isArray(data) && data.length > 0) {
                            setUserName(data[0].usuario.nomeCompleto);
                            setAvailableCredit(data[0].usuario.credito);
                        }
                    } else {
                        console.error('Erro ao carregar dados do usuário:', await response.text());
                    }
                } catch (error) {
                    console.error('Erro ao carregar os dados do usuário:', error);
                }
            }
        };

        fetchData();
    }, [cpf]);

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header />
            <Welcome userName={userName} availableCredit={availableCredit} cpf={cpf} />
            <LinkedStores />
            <Footer />
        </div>
    );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import serverUrl from "../../../../useful/config";

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [searchParams] = useSearchParams();

    const cpf = searchParams.get("cpf");

    const username = 'admin';
    const password = 'admin';
    const credentials = btoa(`${username}:${password}`);

    useEffect(() => {
        const fetchTransactions = async () => {
            if (cpf) {
                try {
                    const response = await fetch(`${serverUrl}extrato/usuario/${cpf}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${credentials}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setTransactions(data);
                    } else {
                        console.error('Erro ao carregar o histórico de transações:', await response.text());
                    }
                } catch (error) {
                    console.error('Erro ao carregar o histórico de transações:', error);
                }
            }
        };

        fetchTransactions();
    }, [cpf]);

    return (
        <section className="md:w-2/3 mx-auto p-6 md:p-8 lg:p-12 bg-gray-100">
            <h3 className="text-2xl font-semibold mb-6 text-gray-800">Transações Recentes</h3>
            <div className="space-y-6">
                {transactions.length > 0 ? (
                    transactions.map((transaction) => (
                        <div
                            key={transaction.id}
                            className="rounded-lg shadow-lg border border-gray-200 bg-white p-6 transition transform hover:scale-105 hover:shadow-xl"
                        >
                            <div className="flex justify-between items-center">
                                <div>
                                    <h4 className="text-lg font-semibold text-gray-700">
                                        {transaction.nomeProduto}
                                    </h4>
                                    <p className="text-sm text-gray-500">
                                        Vendedor: {transaction.vendedor.nomeEmpresa}
                                    </p>
                                </div>
                                <p className="text-lg font-semibold text-red-500">
                                    -${transaction.valor.toFixed(2)}
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500">Nenhuma transação encontrada.</p>
                )}
            </div>
        </section>
    );
};

export default TransactionHistory;

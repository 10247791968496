import React, { useState } from 'react';

import serverUrl from "../../../useful/config";

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const username = 'admin';
  const password = 'admin';
  const credentials = btoa(`${username}:${password}`);

  const handleLogin = () => {
    fetch(`${serverUrl}login/vendedor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      },
      body: JSON.stringify({ email, senha }),
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = `/vendedor/dashboard?email=${email}`;
        } else {
          alert('Login falhou. Verifique suas credenciais.');
        }
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
        alert('Ocorreu um erro. Tente novamente mais tarde.');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-96">
        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">Login - Vendedor</h2>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            id="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Senha"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="flex justify-between mb-4">
          <button
            onClick={handleLogin}
            className="w-full bg-primary text-white py-2 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Login
          </button>
        </div>
        <div className="flex justify-between">
          <button
            onClick={() => (window.location.href = '/vendedor/registro')}
            className="w-full bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Cadastre-se
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from 'react';

import serverUrl from "../../../useful/config";

const Register = () => {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [renda, setRenda] = useState(''); 

  const username = 'admin';
  const password = 'admin';
  const credentials = btoa(`${username}:${password}`);

  const handleRegister = () => {
    fetch(`${serverUrl}usuarios`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${credentials}`
      },
      body: JSON.stringify({
        cpf: cpf,
        senha: senha,
        nomeCompleto: nomeCompleto,
        dataNascimento: dataNascimento,
        renda: renda 
      })
    })
      .then((response) => {
        if (response.ok) {
          alert('Usuário registrado com sucesso!');
          window.location.href = '/usuario/login';
        } else {
          alert('Falha no registro do usuário.');
        }
      })
      .catch((error) => {
        console.error('Erro ao registrar usuário:', error);
        alert('Ocorreu um erro ao registrar. Tente novamente mais tarde.');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-96">
        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">Registro - Usuário</h2>
        <div className="mb-4">
          <input
            type="text"
            id="cpf"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            placeholder="CPF"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            id="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            placeholder="Senha"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            id="nomeCompleto"
            value={nomeCompleto}
            onChange={(e) => setNomeCompleto(e.target.value)}
            placeholder="Nome Completo"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-6">
          <input
            type="date"
            id="dataNascimento"
            value={dataNascimento}
            onChange={(e) => setDataNascimento(e.target.value)}
            placeholder="Data de Nascimento"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="mb-6">
          <input
            type="number" // Alterado para número
            id="renda"
            value={renda}
            onChange={(e) => setRenda(e.target.value)}
            placeholder="Renda"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="flex justify-between mb-4">
          <button
            onClick={handleRegister}
            className="w-full bg-primary text-white py-2 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Registrar
          </button>
        </div>
        <div className="flex justify-between">
          <button
            onClick={() => (window.location.href = '/usuario/login')}
            className="w-full bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Já tem uma conta? Faça login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React from "react";

const Welcome = ({ userName = "Usuário", availableCredit = 0, cpf = 0 }) => {
  return (
    <section className="bg-primary px-6 py-20 md:px-12 lg:px-24 flex flex-col items-center rounded-lg shadow-lg">
      <h2 className="text-3xl md:text-4xl text-secondary font-bold mb-2 text-center">
        Bem-vindo, {userName}!
      </h2>
      <p className="mb-6 text-secondary text-base md:text-lg text-center max-w-lg">
        Você tem R$ {availableCredit.toFixed(2)} disponíveis para gastar
      </p>
      <a
        href={"./transacao?cpf=" + cpf}
        className="block w-full md:w-64 px-4 py-2 bg-secondary text-primary font-semibold rounded-lg text-center transition duration-300 ease-in-out hover:bg-primary hover:text-secondary shadow-md hover:shadow-lg"
      >
        Suas compras
      </a>
    </section>
  );
};

export default Welcome;

import { FaHome, FaListAlt, FaPlus, FaCog, FaUser } from 'react-icons/fa';
import { useSearchParams } from "react-router-dom";

const Footer = () => {
  const [searchParams] = useSearchParams();
  const cpf = searchParams.get("cpf");

  return (
    <footer className="md:hidden fixed bottom-0 w-full z-50">
      <nav className="container mx-auto flex justify-around p-4">
        <a href={`./dashboard?cpf=${cpf}`} className="text-gray-600 flex flex-col items-center">
          <FaHome className="text-xl" />
          <span className="text-sm">Home</span>
        </a>
        <a href={`./transacao?cpf=${cpf}`} className="text-gray-600 flex flex-col items-center">
          <FaListAlt className="text-xl" />
          <span className="text-sm">Transação</span>
        </a>
        <a href="./add" className="text-gray-600 flex flex-col items-center">
          <FaPlus className="text-xl" />
          <span className="text-sm">Adicionar</span>
        </a>
        <a href="./settings" className="text-gray-600 flex flex-col items-center">
          <FaCog className="text-xl" />
          <span className="text-sm">Configurações</span>
        </a>
        <a href="./perfil" className="text-gray-600 flex flex-col items-center">
          <FaUser className="text-xl" />
          <span className="text-sm">Perfil</span>
        </a>
      </nav>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import defaultProfile from '../../../assets/usuario.png'; 

const Profile = () => {
  const [userData] = useState({
    nome: 'João Silva',
    cpf: '123.456.789-00',
    email: 'joao.silva@email.com',
    telefone: '(11) 98765-4321',
    foto: defaultProfile,
  });

  const handleLogout = () => {
    window.location.href = '/login';
  };

  return (
    <div className="flex items-center justify-center mt-32 bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-96">
        <div className="flex justify-center mb-6">
          <img 
            src={userData.foto} 
            alt="Foto do usuário" 
            className="h-24 w-24 rounded-full object-cover border-2 border-primary" 
          />
        </div>

        <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">Perfil do Usuário</h2>
        <div className="mb-4">
          <p className="text-gray-600"><strong>Nome:</strong> {userData.nome}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-600"><strong>CPF:</strong> {userData.cpf}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-600"><strong>Email:</strong> {userData.email}</p>
        </div>
        <div className="mb-4">
          <p className="text-gray-600"><strong>Telefone:</strong> {userData.telefone}</p>
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={handleLogout}
            className="w-full bg-primary text-white py-2 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;

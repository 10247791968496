import React, { useState, useEffect } from "react";
import serverUrl from "../../../useful/config";

const SellerDashboard = () => {
    const [email, setEmail] = useState('');
    const [nomeProduto, setNomeProduto] = useState('');
    const [valor, setValor] = useState('');
    const [cpfUsuario, setCpfUsuario] = useState('');
    const [extrato, setExtrato] = useState([]);
    const username = 'admin';
    const password = 'admin';
    const credentials = btoa(`${username}:${password}`);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailParam = urlParams.get('email');
        setEmail(emailParam);

        if (emailParam) {
            fetch(`${serverUrl}extrato/vendedor/${emailParam}`)
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        setExtrato(data);
                    } else {
                        setExtrato([]);
                    }
                })
                .catch((error) => console.error('Erro ao carregar o extrato:', error));
        }
    }, []);

    const sellProduct = () => {
        fetch(`${serverUrl}vendas`, { 
            method: 'POST',
            hheaders: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${credentials}`
          },
          body: JSON.stringify({ nomeProduto, valor, cpfUsuario, emailVendedor: email})
        })
        .then(response => {
          if (response.ok) {
              alert('Produto vendido com sucesso!');
          } else {
              alert('Falha na venda do produto.');
          }
        });
    };

    return (
        <div className="container mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6 text-center">
                Bem-vindo, {extrato.length > 0 ? extrato[0].usuario.nomeEmpresa : 'Vendedor'}
            </h1>
            <div className="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 className="text-xl font-semibold mb-4">Venda de Produto</h2>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Nome do Produto</label>
                    <input
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        type="text"
                        placeholder="Nome do Produto"
                        value={nomeProduto}
                        onChange={(e) => setNomeProduto(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Valor do Produto</label>
                    <input
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        type="text"
                        placeholder="Valor do Produto"
                        value={valor}
                        onChange={(e) => setValor(e.target.value)}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">CPF do Usuário</label>
                    <input
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        type="text"
                        placeholder="CPF do Usuário"
                        value={cpfUsuario}
                        onChange={(e) => setCpfUsuario(e.target.value)}
                    />
                </div>
                <button
                    className="bg-blue-500 text-white p-2 rounded-lg shadow-lg hover:bg-blue-600 transition duration-200"
                    onClick={sellProduct}
                >
                    Vender
                </button>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Extrato de Vendas</h2>
                <table className="min-w-full bg-white border">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 bg-gray-200 border">Produto</th>
                            <th className="py-2 px-4 bg-gray-200 border">Valor</th>
                            <th className="py-2 px-4 bg-gray-200 border">Cliente</th>
                        </tr>
                    </thead>
                    <tbody>
                        {extrato.length > 0 ? (
                            extrato.map((compra, index) => (
                                <tr key={index}>
                                    <td className="border px-4 py-2">{compra.nomeProduto}</td>
                                    <td className="border px-4 py-2">R$ {compra.valor.toFixed(2)}</td>
                                    <td className="border px-4 py-2">{compra.usuario.cpf} ({compra.usuario.nomeCompleto})</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="border px-4 py-2 text-center">Nenhuma compra encontrada.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SellerDashboard;
